import "./header.scss";
import * as dompack from 'dompack';

class navigation
{
  constructor(node)
  {
    this.node = node;

    this.searchcontainer = dompack.qS(node.parentNode,".header__search");

    this.node.addEventListener("click", ev => this.onClick(ev));
    this.node.addEventListener("keyup", ev => this.onClick(ev));

    dompack.qS(".header__mobiletoggle").addEventListener("click", ev => this.toggleMobileMenu(ev));
    dompack.qS(".header__mobiletoggle").addEventListener("keyup", ev => this.toggleMobileMenu(ev));

    window.addEventListener("load", ev => this.checkPosition(ev) );
    window.addEventListener("resize", ev => this.checkPosition(ev) );
    window.addEventListener("slimmezorgestafette:refreshcontent", ev => this.checkPosition(ev) );
    document.fonts.ready.then(() => this.checkPosition( {type:'load'} ) );

    this.checkPosition();
  }

  onClick(ev)
  {
    if( ev.type == "keyup" && ev.keyCode != 13 /* ENTER */ )
      return;

    if( document.documentElement.classList.contains("hidedesktopnav") )
    {  //Note: 'hidedesktopnav' is set when using mobile layout
      if( ev.target.nodeName == "A" && ev.target.parentNode.classList.contains("haschildren") )
      {
        if( !ev.target.parentNode.classList.contains("showchildren") )
        { //Only follow link on second click
          ev.preventDefault();
          ev.target.parentNode.classList.add("showchildren");
        }
      }
      else if( ev.target.classList.contains("haschildren") )
        ev.target.classList.toggle("showchildren");
      else if( ev.target.parentNode.classList.contains("haschildren") )
        ev.target.parentNode.classList.toggle("showchildren");
    }
    else if( ev.target.classList.contains("mainnav__subitems__toggle") )
      ev.target.parentNode.classList.toggle("showchildren");
    else if( ev.target.nodeName == "A" && ev.target.parentNode.classList.contains("haschildren") )
    {
      if( !ev.target.parentNode.classList.contains("showchildren") )
      {
        if( !ev.target.parentNode.classList.contains("mainnav__rootitem") )
          ev.preventDefault();

        ev.target.parentNode.classList.add("showchildren");
      }
    }
  }

  toggleMobileMenu(ev)
  {
    if( ev.type == "keyup" )
    {
      if( (ev.keyCode != 13 /* ENTER */ && ev.keyCode != 27 /* ESC */) || (ev.keyCode == 27 && !document.documentElement.classList.contains("showmobilemenu")) )
        return;
    }

    document.documentElement.classList.toggle("showmobilemenu");
  }

  checkPosition(ev)
  {
    if( !this.viewport || (ev && ev.type == "resize") )
      this.viewport = { x : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                      , y : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                      };

    //Check if headerbar still fits in window
    document.documentElement.classList.remove("hidedesktopnav"); //first reset
    let boundright = this.searchcontainer.getBoundingClientRect().right;
    if( this.viewport.x < boundright )
      document.documentElement.classList.add("hidedesktopnav");
  }
}

dompack.register(".mainnav", node => new navigation(node) );
