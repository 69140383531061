import "./search.scss";
import * as dompack from 'dompack';
import consiliosearch from "@mod-consilio/js/internal/search.rpc.json";

/*
  cSearchSuggest
    suggest container is inserted in end of search form
  options: catalog: string , consilio catalog name
           rpc: boolean    , switch when option is selected then plain submit or just fire submit event for rpc
*/
class cSearchSuggest
{
  constructor( inputnode )
  {
    this.inputnode = inputnode;

    this.options = JSON.parse(inputnode.getAttribute("data-suggest"));

    if( !this.options.catalog )
    {
      console.warn("No catalog set");
      return;
    }

    this.formnode = dompack.closest(this.inputnode, "form");
    this.attachnode = this.formnode;

    if( inputnode.dataset.suggestparent )
    {
      this.attachnode = dompack.qS(inputnode.dataset.suggestparent);
      if( !this.attachnode )
      {
        console.error("Invalid suggest parent container");
        return;
      }
    }

    this.autoposition = inputnode.dataset.autoposition;

    if( this.autoposition )
    {
      window.addEventListener("resize", ev => {
        if( !this.suggestwrapper )
          return;
        this.positionSuggestWrapper();
      });
    }

    this.history = [];

    document.body.addEventListener("click", ev =>
    {
      if( !this.suggestwrapper )
        return;

      let chknode = dompack.closest(ev.target, "form");
      if( !chknode || chknode != this.formnode )
        this.removeSuggestions();
    });

    this.words = this.inputnode.value;
    this.inputnode.addEventListener("keyup", ev =>
    {
      if( this.suggestwrapper && ev.keyCode == 40 )
      { //down 40, up 38
        ev.preventDefault();
        this.suggestwrapper.querySelector("li").focus();
      }

      if( this.updatetimer )
        clearTimeout(this.updatetimer);

      let inpval = this.inputnode.value;
      if( inpval.trim )
        inpval = inpval.trim();

      if( inpval != this.words )
        this.updatetimer = setTimeout( ev => this.updateList( inpval ), 200);
    });

    this.inputnode.addEventListener("search", ev => this.removeSuggestions() );//case search clear field
  }

  async updateList( words )
  {
    this.words = words;

    let minwordlength = 3;

    //first check if we have already suggestions for given input
    for( let i = this.history.length - 1; i >= 0 && this.words.length >= minwordlength; --i)
    {
      if( this.history[i].words == this.words )
      {
        this.updateSuggestions(this.history[i].values);
        return;
      }
    }

    if( this.words != "" && this.words.length >= minwordlength )
    {
      if(this.suggestionrpc)
        consiliosearch.rpcResolve(this.suggestionrpc, null);

      this.suggestionrpc = consiliosearch.suggest(
        { type: "catalog"
        , catalog: this.options.catalog
        }
        , this.words
        , { doccount: ""
          , count: 10
          });

      let results = await this.suggestionrpc;
      if(results)
        this.updateSuggestions(results.values);
    }
    else if( this.suggestwrapper )
      this.removeSuggestions();
  }

  updateSuggestions( suggestions )
  {
    this.formnode.classList.add("suggestionsactive");

    this.history.push({"words" : this.words, "values" : suggestions });
    if( this.history.length > 100 ) //limit nr items in history
      this.history.shift();

    if( !this.suggestwrapper )
    {
      this.listitems = [];
      this.suggestwrapper = <ul class="wh-autocomplete-values" style="display:none;" />;

      this.attachnode.appendChild(this.suggestwrapper);

      if( this.autoposition )
        this.positionSuggestWrapper();

      this.suggestwrapper.addEventListener("keydown", ev =>
      {
        if( ev.keyCode == 38 )
        { // Up
          ev.preventDefault();

          let focusednode = this.inputnode;
          for(let i = this.listitems.length - 1; i >= 0; --i)
          {
            if( document.activeElement == this.listitems[i] )
            {
              if( i > 0 )
                focusednode = this.listitems[i - 1];
              break;
            }
          }
          focusednode.focus();
        }
        else if( ev.keyCode == 40 )
        {// Down
          ev.preventDefault();

          let focusednode = this.inputnode;
          for(let i = 0; i < this.listitems.length; ++i)
          {
            if( document.activeElement == this.listitems[i] )
            {
              if(i < this.listitems.length - 1)
                focusednode = this.listitems[i + 1];
              break;
            }
          }
          focusednode.focus();
        }
        else if( ev.keyCode == 27 ) // Esc
        {
          this.inputnode.focus();
          this.removeSuggestions();
        }
        else if( ev.keyCode == 13 ) // Enter
        {
          let item = dompack.closest( ev.target, "li");
          if( item )
          {
            this.inputnode.value = item.getAttribute("data-value");
            this.removeSuggestions();//remove list

            if( this.options.rpc ) // trigger Rpc
              dompack.dispatchCustomEvent(this.formnode, "submit", { bubbles: false, cancelable: true});
            else
              this.formnode.submit();//basic submit
          }
        }
      });
    }

    if( !suggestions.length )
      return;//Just keep previous results visible

    this.suggestwrapper.style.display = "";
    dompack.empty(this.suggestwrapper);//first empty container

    for( let item of suggestions )
    {
      let node = <li class="suggestion" tabindex="0" data-value={item.value} />;
      node = highlightMatch(node, item.value, this.words );

      node.addEventListener("click", ev => {
        this.inputnode.value = item.value;
        this.removeSuggestions();//hide/remove list

        if( this.options.rpc ) // trigger Rpc
          dompack.dispatchCustomEvent(this.formnode, "submit", { bubbles: false, cancelable: true});
        else
          this.formnode.submit();//basic submit
      });

      this.listitems.push(node);

      this.suggestwrapper.appendChild(node);
    }
  }

  positionSuggestWrapper()
  {
    let pos1 = this.attachnode.getBoundingClientRect();
    let pos2 = this.inputnode.getBoundingClientRect();

    //First set position wrapper to zero
    this.suggestwrapper.style.left = "0px";
    this.suggestwrapper.style.right = "0px";
    this.suggestwrapper.style.top = "0px";
    this.suggestwrapper.clientWidth;//force css refresh

    let x1 = pos2.left - pos1.left;
    if( x1 != 0 )
      this.suggestwrapper.style.left = x1 + "px";

    let x2 = pos1.right - pos2.right;
    if( x2 != 0 )
      this.suggestwrapper.style.right = x2 + "px";

    let y1 = pos2.bottom - pos1.top;
    if( y1 != 0 )
      this.suggestwrapper.style.top = y1 + "px";
  }

  removeSuggestions()
  {
    this.formnode.classList.remove("suggestionsactive");

    if( !this.suggestwrapper )
      return;
    this.suggestwrapper.parentNode.removeChild( this.suggestwrapper );
    this.suggestwrapper = null;
  }
}

function highlightMatch(el, value, words)
{
  words = words.toUpperCase();
  let i = value.toUpperCase().indexOf(words);
  let l = words.length;
  let previ = 0;
  while( i > -1 )
  {
    if( i > previ )
      el.appendChild( document.createTextNode(value.substring(previ,i)) );

    el.appendChild( <span class="match">{value.substr(i, words.length)}</span> );//

    previ = i + l;
    i = value.toUpperCase().indexOf(words, i + l);
  }
  if( value.length > previ )
    el.appendChild( document.createTextNode(value.substr(previ)) );

  return el;
}

dompack.register("input[data-suggest]", node => new cSearchSuggest( node ) );

class searchForm
{
  constructor(node)
  {
    this.node = node;
    this.node.addEventListener("submit", ev => this.onSubmit(ev));
  }

  getUrlParam(name)
  {
    let val = new URL(location.href).searchParams.get(name);
    return val ? decodeURIComponent(val) : "";
  }

  onSubmit(ev)
  {
    let words = this.node.words.value.trim();
    if( !words )
    {
      this.node.words.focus();
      ev.preventDefault();
    }
  }
}

dompack.register(".header__search form", node => new searchForm(node) );
