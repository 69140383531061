import * as dompack from 'dompack';
import { srt2webvtt } from './convert_srt';
import './rovid.scss';

let videojs;

class VideoPlayer
{
  constructor(node)
  {
    this.node = node;
    this.initVideo();
  }

  async initVideo()
  {
    if( !videojs )
    {
      let load_css = System.import('video.js/dist/video-js.css');
      let load_js = System.import('video.js');

      await load_css;
      videojs = await load_js;
      videojs = videojs.default;
    }

    this.setupVideo();
  }

  setupVideo()
  {
    const elVideo = this.node.querySelector("video");

    this.videoplayer = new videojs(elVideo, { controls: true
                                            , preload: 'auto'
                                            , aspectRatio: '16:9'
                                            });

    // remove the normal subtitles button
    const controlBar = this.videoplayer.getChild('ControlBar');
    controlBar.removeChild(controlBar.getChild('SubsCapsButton'));

    // setup subtitles
    this.videoplayer.on('ready', async() =>
    {
      const srtURL = elVideo.dataset ? elVideo.dataset.srt : '';
      if (srtURL)
      {
        this.addRemoteTextTrackBySRT(srtURL,
            { button: this.node.querySelector('.vjs-captions-toggle.cc')
            , type: 'subtitles'
            , className: 'cc'
            , title: 'CC'
            });
      }

      const adURL = elVideo.dataset ? elVideo.dataset.adsrt : '';
      if (adURL)
      {
        this.addRemoteTextTrackBySRT(adURL,
            { button: this.node.querySelector('.vjs-captions-toggle.ad')
            , type: 'captions'
            , className: 'ad'
            , title: 'AD'
            });
      }
    });
  }

  // adds a track and a button to enable it
  async addRemoteTextTrackBySRT(srtURL = '', options = {})
  {
    const webVTTURL = await this.convertSrtURLToWebVTT(srtURL);
    if (webVTTURL)
    {
      this.videoplayer.addRemoteTextTrack(
        { kind: options.type
        , src: webVTTURL
        , mode: 'hidden'
        }, true);

      // adding a button to the player
      let button = this.videoplayer.addChild('button', {
        clickHandler: (ev) => {
          let tracks = this.videoplayer.remoteTextTracks();

          let isactive;
          for( let i = 0; i < tracks.length; ++i)
          { // loop through tracks to disable or enable them
            if( tracks[i].kind == options.type )
            {
              tracks[i].mode = tracks[i].mode == "showing" ? 'hidden' : 'showing';
              isactive = tracks[i].mode == "showing";
            }
          }

          for( let button of this.node.querySelectorAll('.vjs-captions-toggle.' + options.className) )
            dompack.toggleClass(button, 'vjs-on', isactive);// toggle class
        }
      });

      let el = button.el();
      el.className = "vjs-captions-toggle " + options.className;
      el.querySelector('.vjs-icon-placeholder').textContent = options.title;
      this.videoplayer.getChild('ControlBar').addChild(button);
    }
  }

  // returns a DOMString containing a URL to a WebVTT file (converted from SRT)
  async convertSrtURLToWebVTT(url = '')
  {
    const srtBlob = await fetch(url).then(r => r.text());
    if( !srtBlob )
      return;

    const convertedWebVTT = srt2webvtt(srtBlob);
    const vttBlob = new Blob([convertedWebVTT], {type : 'text/vtt'});
    return URL.createObjectURL(vttBlob);
  }
}

dompack.register(".widget-rovid", (node) => new VideoPlayer(node));
