import "./photoalbum.scss";
import * as dompack from "dompack";
import cMediaOverlay from "../../shared/mediaoverlay";

function showPhotoOverlay(overlay, ev)
{
  if( ev.type == "keyup" && ev.keyCode != 13 /* ENTER */ )
    return;

  let thumb = ev.target.closest(".widget-photoalbum__preview");
  let idx = 0;
  if( thumb )
    idx = 1*thumb.dataset.seqnr;
  overlay.showOverlay(idx);
 }

dompack.register(".widget-photoalbum__container[data-photos]", node => {
  let overlay = new cMediaOverlay(JSON.parse(node.dataset.photos), node.dataset.title);
  node.addEventListener("click", ev => showPhotoOverlay(overlay, ev) );
  node.addEventListener("keyup", ev => showPhotoOverlay(overlay, ev) );
});
