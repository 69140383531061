import "./featured.scss";
import * as dompack from 'dompack';
import * as swipe from 'dompack/browserfix/swipelistener';

class featuredItemsSlider
{
  constructor(node)
  {
    this.items = dompack.qSA(node,".widget-featured__item");
    if( this.items.length <= 1 )
      return;

    this.jmpbtncontainer = dompack.qS(node, ".widget-featured__jumpbuttons");
    this.container = dompack.qS(node, ".widget-featured__itemlist");

    node.addEventListener("click", ev => this.onClickOrKey(ev));
    node.addEventListener("keydown", ev => this.onClickOrKey(ev));

    window.addEventListener("resize", ev => this.onResize(ev) );
    window.addEventListener("load", ev => this.onResize(ev) );

    swipe.enable(node);
    node.addEventListener("dompack:swipe", ev => {
      if( ev.detail.direction == "e" )
        this.goto(this.idx - 1);
      else if( ev.detail.direction == "w" )
        this.goto(this.idx + 1);
    });
  }

  onResize()
  {
    let colcount = Math.round(this.container.clientWidth / this.items[0].clientWidth);

    if( !this.visiblecount || colcount != this.visiblecount )
    {
      if( !this.idx )
        this.idx = 0;
      else //Keep current first item visible
      {
        this.idx = Math.floor((this.visiblecount*this.idx) / colcount);
        this.container.style.transform = "translateX(" + (-100*this.idx) + "%)";
      }

      this.visiblecount = colcount;
      this.renderJumpBtns();
    }
  }

  onClickOrKey(ev)
  {
    if( ev.type == "keydown" )
    {
      if( ev.keyCode == 37 ) //left
        this.goto(this.idx - 1);
      else if( ev.keyCode == 39 ) //right
        this.goto(this.idx + 1);

      if( ev.keyCode != 13 /* ENTER */ )
        return;
    }

    if( ev.target.closest(".widget-featured__jumpbuttons__previous") )
    {
      this.goto(this.idx - 1);
      return;
    }

    if( ev.target.closest(".widget-featured__jumpbuttons__next") )
    {
      this.goto(this.idx + 1);
      return; 
    }

    let btn = ev.target.closest(".widget-featured__jumpbuttons__button[data-idx]");
    if( btn )
      this.goto(1*btn.dataset.idx);
  }

  goto(idx)
  {
    if( idx < 0 || idx > this.slidescount - 1 || idx == this.idx )
      return;

    dompack.toggleClass(this.jmpbtncontainer, "widget-featured__jumpbuttons--start", idx < 1);
    dompack.toggleClass(this.jmpbtncontainer, "widget-featured__jumpbuttons--end", idx >= this.slidescount - 1);

    let oldbtn = dompack.qS(this.jmpbtncontainer, ".widget-featured__jumpbuttons__button--active");
    if( oldbtn )
      oldbtn.classList.remove("widget-featured__jumpbuttons__button--active");

    let newbtn = dompack.qS(this.jmpbtncontainer, ".widget-featured__jumpbuttons__button[data-idx='" + idx + "']");
    if( newbtn )
      newbtn.classList.add("widget-featured__jumpbuttons__button--active");

    this.container.style.transform = "translateX(" + (-100*idx) + "%)";
    this.idx = idx;
  }

  renderJumpBtns()
  {
    dompack.empty(this.jmpbtncontainer);

    this.slidescount = Math.ceil(this.items.length / this.visiblecount);
    if( this.slidescount <= 1 )
      return;

    this.jmpbtncontainer.appendChild(<button aria-label="Vorige slide" tabindex="0" class="widget-featured__jumpbuttons__previous far fa-angle-left" type="button" />);
    for( let i = 0; i < this.slidescount; ++i )
      this.jmpbtncontainer.appendChild(<button tabindex="0" data-idx={i} class={i == this.idx ? "widget-featured__jumpbuttons__button widget-featured__jumpbuttons__button--active" : "widget-featured__jumpbuttons__button"} type="button" aria-label={"Toon slide " + (i+1)} />);
    this.jmpbtncontainer.appendChild(<button tabindex="0" aria-label="Volgende slide" class="widget-featured__jumpbuttons__next far fa-angle-right" type="button" />);

    dompack.toggleClass(this.jmpbtncontainer, "widget-featured__jumpbuttons--start", this.idx < 1);
    dompack.toggleClass(this.jmpbtncontainer, "widget-featured__jumpbuttons--end", this.idx >= this.slidescount - 1);
  }
}

dompack.register(".widget-featured--slides", node => new featuredItemsSlider(node));
