import "./video.scss";
import * as dompack from "dompack";
import * as consenthandler from '@mod-publisher/js/analytics/consenthandler.es';

consenthandler.setup("sze2021-consent", () => { /* Nothing, just handle it within video consent overlay */ } );

dompack.register(".wh-video", widget => { 

  //Add title after play button
  let playbtn = dompack.qS(widget,".wh-video__playbutton");
  dompack.after(playbtn,<div class="wh-video__title">{widget.title}</div>);

  if( consenthandler.hasConsent("thirdparty") )
    return;

  let overlaynode = dompack.qS(widget, ".wh-requireconsent__overlay" );
  if( !overlaynode )
    return;

  //First add extra layout wrapper for positioning consent overlay
  const wrapper = <div class="widget widget--wrapper" />;
  dompack.before( widget, wrapper);
  wrapper.appendChild(widget);

  const videoinfo = JSON.parse(widget.closest(".wh-video").dataset.whVideo);
  const networkname = videoinfo.network == "youtube" ? "YouTube" : videoinfo.network;

  //Add texts
  overlaynode.appendChild(<h2>Dit is een <span class="network">{networkname}</span> video</h2>);
  overlaynode.appendChild(<p><span class="network">{networkname}</span> video’s kunnen alleen worden afgespeeld worden als je <span class="accept" role="button" tabindex="0">cookies van derden accepteert</span>.</p>);

  overlaynode.addEventListener("click", ev => {
    if( ev.target.closest(".accept") )
      consenthandler.setConsent(["thirdparty"]);
  });

  overlaynode.addEventListener("keyup", ev => {
    if( ev.keyCode == 13 && ev.target.closest(".accept") )
      consenthandler.setConsent(["thirdparty"]);
  });
});

consenthandler.onConsent("thirdparty", () => {
  dompack.qSA(".wh-video .wh-requireconsent__overlay").forEach(overlaynode => dompack.remove(overlaynode) );
});
