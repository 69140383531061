import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';
import * as preload from 'dompack/extra/preload';
import * as whintegration from '@mod-system/js/wh/integration';
import shareCurrentPage from './shared/sharepage';

import './footer';
import './header';
import './shared/forms/forms';
import './shared/rtd/rtd';
import './shared/shared.scss';
import './slimmezorgestafette.scss';

import './pages/news';
import './pages/search';
import './widgets';

window.event_supportspassive = false;
document.createElement("div").addEventListener("test", _ => {}, { get passive() { window.event_supportspassive = true; }});

dompack.register(".sharepage", node => {
  node.addEventListener("click", ev => {
    let sharebtn = ev.target.closest(".wh-share");
    if( sharebtn )
      shareCurrentPage( sharebtn );
  });
});

/*
  Load images for elements where data-loadimage is set when visible in viewport
  img element will be appended inside this element
  and adds scrollfx when elements with scrollfx class gets into view
*/
class scrollListener
{
  constructor()
  {
    this.refresh();

    if( window.event_supportspassive )
      window.addEventListener("scroll", ev => this.onScroll(ev), { passive: true } );
    else
      window.addEventListener("scroll", ev => this.onScroll(ev) );

    window.addEventListener("slimmezorgestafette:refreshcontent", ev => {
      if( ev.detail && ev.detail.container )
        this.refresh(ev.detail.container);
      this.checkVisibleElements(ev);
    } );

    window.addEventListener("resize", ev => this.checkVisibleElements(ev) );
    window.addEventListener("load", ev => this.checkVisibleElements(ev) );
  }

  refresh(container)
  {
    if( !container )
      container = document;

    this.imagecontainers = [];
    for( let node of dompack.qSA(container,"[data-loadimage]") )
      this.imagecontainers.push(node);

    this.scrollfxnodes = [];
    for( let node of dompack.qSA(".scrollfx:not(.scrollfx--done)") )
      this.scrollfxnodes.push(node);

    this.checkVisibleElements({type:"refresh"});
  }

  onScroll(ev)
  {
    //sticky header
    let y = window.scrollY;//document.documentElement.scrollTop;
    if( !this.isSticky && y >= 100 )
      document.documentElement.classList.add("stickyheader");
    else if(this.isSticky && y < 100 )
      document.documentElement.classList.remove("stickyheader");

    this.isSticky = y > 100;

    //Now check for scrollfx and lazyloading images
    this.checkVisibleElements(ev);
  }

  checkVisibleElements(ev)
  {
    if( !this.imagecontainers.length && !this.scrollfxnodes.length )
      return;//Nothing todo

    if( !this.viewport || (ev && ev.type == "resize") )
    {
      this.viewport = { x : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                      , y : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                      };
    }

    for( let i = this.scrollfxnodes.length - 1; i >= 0; --i )
    {
      let pos = this.scrollfxnodes[i].getBoundingClientRect();
      if( !pos.width )
        continue;//Probably hidden element

      if( ev && ev.type == "refresh" && pos.bottom < 0 )
        this.scrollfxnodes[i].classList.add("scrollfx--fromtop");

      if( pos.bottom > 0 && pos.top < this.viewport.y )
      {
        this.scrollfxnodes[i].classList.add("scrollfx--done");
        dompack.dispatchCustomEvent(this.scrollfxnodes[i], "slimmezorgestafette:scrollfx-visible", { bubbles    : true
                                                                                                   , cancelable : false
                                                                                                   });

        this.scrollfxnodes.splice(i,1);
      }
    }

    for( let i = this.imagecontainers.length - 1; i >= 0; --i )
    {
      let pos = this.imagecontainers[i].getBoundingClientRect();
      if( !pos.width )
        continue;//Probably hidden element

      if( pos.bottom > 0 && pos.top < this.viewport.y )
      {
        let srcset = this.imagecontainers[i].dataset.loadimage;
        this.preloadImage( this.imagecontainers[i], srcset );
        //remove attribute so image is not loaded multipletimes incase of refresh
        this.imagecontainers[i].removeAttribute("data-loadimage");
        this.imagecontainers[i].classList.add("image-loaded");
        this.imagecontainers.splice(i,1);
      }
    }
  }

  async preloadImage( wrappernode, srcset )
  {
    let preloadedimage = await preload.promiseImage( srcset.split(" ")[0] );
    if( preloadedimage )
    {
      let title = wrappernode.getAttribute("title");
      preloadedimage.node.setAttribute("alt", title ? title : "");
      preloadedimage.node.setAttribute("srcset", srcset);
      preloadedimage.node.style.opacity = 0;
      preloadedimage.node.style.transition = "opacity 300ms";
      wrappernode.appendChild( preloadedimage.node );
      wrappernode.clientWidth;// force css update
      preloadedimage.node.style.opacity = 1;
    }
  }
}

function startTracking()
{

(function(window, document, dataLayerName, id) {
window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="//statistiek.rijksoverheid.nl/containers/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', '13352a06-306c-4425-bc81-3f2a7749ed4a');

}

dompack.onDomReady(() =>
{
  if( whintegration.config.dtapstage == "production" ) // only the live site should send tracking info
    startTracking();

  new scrollListener();
});
